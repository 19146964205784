import React, { useEffect } from "react";
import { BASE_URL, DOMAIN } from "./../../global";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import Global from "../image/sticker.jpeg";
import TableContainer from "@mui/material/TableContainer";
import { useReactToPrint } from "react-to-print";
import Button from "@mui/material/Button";
import HelpIcon from "@mui/icons-material/Help";
import moment from "moment";
import { useParams } from "react-router-dom";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import QRCode from "react-qr-code";
import "./certificateList.css";
import { countOfStickerAnalytics } from "../../utils/analytics/certificateAnalytics";
import { multipleQRCertificateGuide } from "./guide";
import axiosWithToken from "../../utils/components/axiosTokenConfig";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");



export default function MultiQr() {
  const multipleQRCertificateGuides = multipleQRCertificateGuide();
  const printComponentRef = React.useRef();
  const [allData, setAllData] = React.useState([]);
  const params = useParams();
  const fullHeader = true;
  const isHeaderAtLeft = true;
  let certificateIds = params.certificateId;
  const certificateIdsArray = certificateIds.split(",").map(Number);
  const [stikerHeight, setStikerHeight] = React.useState(205);
  const [stikerWidth, setStikerWidth] = React.useState(375);
  // const maxWidth = 210;
  // const maxHeight = 297;
  const sizeOptions = [
    { width: "30.7%", height: "16.8%", label: "Width: 75mm, Height: 50mm" },
    { width: "23.8%", height: "10.10%", label: "Width: 50mm, Height: 30mm" },
    { width: "40.4%", height: "18.5%", label: "Width: 85mm, Height: 55mm" },
  ];
  const [selectedSizes, setSelectedSizes] = React.useState({
    width: sizeOptions[0].width,
    height: sizeOptions[0].height,
  });
  const handleSizeChange = (event) => {
    let deviceWidth = document.getElementById("printStiker").clientWidth;
    // console.log('deviceWidth',deviceWidth);
    const { value } = event.target;
    const [xMM, yMM] = value.split("-");

    const heightPixel = (parseFloat(yMM) * deviceWidth) / 100;
    const widthPixel = (parseFloat(xMM) * deviceWidth) / 100;
    // console.log('heightPixel', heightPixel ,"widthPixel",widthPixel);
    // console.log("xMM : ",xMM , "yMM : ",yMM);
    setStikerHeight(heightPixel);
    setStikerWidth(widthPixel);
    setSelectedSizes({ width: xMM, height: yMM });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const getExpenseList = (event) => {
    let payload = {
      query: `SELECT certificates.DUCID AS certificate_DUCID, certificates.extraColumns AS certificate_extraColumns, certificates.calibrationDate AS certificate_calibrationDate, certificates.nextDueDate AS certificate_nextDueDate, inst.instrumentName AS inst_instrumentName, certificates.id AS certificate_id, certificateAnalytics.stickerDownloadCounter AS certificateAnalytics_stickerDownloadCounter, certificateAnalytics.certificateDownloadCounter AS certificateAnalytics_certificateDownloadCounter, certificates.locationOfInstrument AS certificate_locationOfInstrument, certificates.certificateNumber AS certificate_certificateNumber, users.userName AS datasheet_calibratedby, datasheet.revisionNumber AS datasheet_revisionNumber FROM certificates LEFT JOIN instruments AS inst ON certificates.instrumentId = inst.id LEFT JOIN datasheets AS datasheet ON certificates.id = datasheet.id LEFT JOIN certificateAnalytics ON certificates.id = certificateAnalytics.certificateId LEFT JOIN users ON datasheet.calibratedby = users.id WHERE certificates.id IN (${params.certificateId})`,
    };

    axiosWithToken
      .post(BASE_URL + `dynamic`, payload)
      .then((res) => {
        var splitIdArray = [];
        params.certificateId
          ? params.certificateId.split(",").map((ids) => {
              for (let i = 0; i < res.data.length; i++) {
                if (parseInt(ids) == res.data[i].certificate_id) {
                  splitIdArray.push(res.data[i]);
                }
              }
            })
          : console.log("no data");
        setAllData(splitIdArray);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
    onAfterPrint: () => {
      refresh(); // Reload the page after printing is completed
    },
  });

  const stickersPerPage = 6;
  const renderQrModal = () => {
    return allData.map((data, index) => {
      return (
        <div
          className="item"
          style={{
            height: "100%",            
            pageBreakAfter: index % stickersPerPage === 5 ? "always" : "auto",
          }}
        >
          <div className="qrModalStyle">
            <img
              src={Global}
              style={{ width: "90%", height: "23%", margin:"auto" }}
              alt="rq-logo"
            ></img>

            <div style={{ flex: "1", display: "flex" }}>
              <div style={{ width: "95%", margin: "auto" }}>
                <table
                  style={{
                    width: "100%",
                    height: "100%",
                    tableLayout: "fixed",
                  }}
                >
                  <tbody>
                  <tr>
                      <td className="stickerTdM" 
                       style={{ fontSize: selectedSizes.width === '30.7%' ? '50%' : selectedSizes.width === '23.8%' ? '30%' : '60%' }}>
                        <b>equipment name</b>{" "}
                      </td>
                      <td
                        className="dataDivFromStickerM"
                        style={{ fontSize: selectedSizes.width === '30.7%' ? '50%' : selectedSizes.width === '23.8%' ? '30%' : '60%' }}
                      >
                        <b>
                          {data?.certificate_extraColumns ? JSON.parse(data?.certificate_extraColumns)["Equipment Name"] : {}}
                          </b>
                      </td>
                    </tr>
                    <tr>
                      <td className="stickerTdM" 
                       style={{ fontSize: selectedSizes.width === '30.7%' ? '50%' : selectedSizes.width === '23.8%' ? '30%' : '60%' }}>
                        <b>equipment id</b>{" "}
                      </td>
                      <td
                        className="dataDivFromStickerM"
                        style={{ fontSize: selectedSizes.width === '30.7%' ? '50%' : selectedSizes.width === '23.8%' ? '30%' : '60%' }}
                      >
                        <b>
                        {data?.certificate_extraColumns ? JSON.parse(data?.certificate_extraColumns)["Equipment ID"] : {}}
                        </b>
                      </td>
                    </tr>
                    {/* <tr>
                      <td className="stickerTdM" 
                       style={{ fontSize: selectedSizes.width === '30.7%' ? '50%' : selectedSizes.width === '23.8%' ? '30%' : '60%' }}>
                        <b>certificate no</b>{" "}
                      </td>
                      <td
                        className="dataDivFromStickerM"
                        style={{ fontSize: selectedSizes.width === '30.7%' ? '50%' : selectedSizes.width === '23.8%' ? '30%' : '60%' }}
                      >
                        <b>{`${data.certificate_certificateNumber}${
                          data.datasheet_revisionNumber > 0
                            ? `/AMD-${data.datasheet_revisionNumber}`
                            : ""
                        }`}</b>
                      </td>
                    </tr> */}
                    <tr>
                      <td className="stickerTdM" style={{ fontSize: selectedSizes.width === '30.7%' ? '50%' : selectedSizes.width === '23.8%' ? '30%' : '60%' }}>
                        <b>instrument name</b>
                      </td>
                      <td
                        className="dataDivFromStickerM"
                        // style={{
                        //   fontSize:
                        //     data.inst_instrumentName &&
                        //     data.inst_instrumentName.length > 30
                        //       ? "60%"
                        //       : "60%",
                        // }}
                        style={{ fontSize: selectedSizes.width === '30.7%' ? '50%' : selectedSizes.width === '23.8%' ? '30%' : '60%' }}
                      >
                        <b>{data.inst_instrumentName}</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="stickerTdM" style={{ fontSize: selectedSizes.width === '30.7%' ? '50%' : selectedSizes.width === '23.8%' ? '30%' : '60%' }}>
                        <b>instrument id no</b>
                      </td>
                      <td
                        className="dataDivFromStickerM"
                        style={{ fontSize: selectedSizes.width === '30.7%' ? '50%' : selectedSizes.width === '23.8%' ? '30%' : '60%' }}
                      >
                        <b>{data.certificate_DUCID}</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="stickerTdM" style={{ fontSize: selectedSizes.width === '30.7%' ? '50%' : selectedSizes.width === '23.8%' ? '30%' : '60%' }}>
                        <b>location</b>
                      </td>
                      <td
                        className="dataDivFromStickerM"
                        style={{ fontSize: selectedSizes.width === '30.7%' ? '50%' : selectedSizes.width === '23.8%' ? '30%' : '60%' }}
                      >
                        <b>{data.certificate_locationOfInstrument}</b>
                      </td>
                    </tr>
                    <tr style={{height: "15px"}}>
                      <td
                        className=" bottomDiv"
                        style={{ display: "flex", border: "none" }}
                        // colSpan={2}
                      >
                        <div
                          style={{
                            width: "75%",
                            textAlign:"left",
                            marginTop:"-2px"
                          }}
                        >
                          <div
                            className="bottomDivChildM"
                            style={{ fontSize: selectedSizes.width === '30.7%' ? '50%' : selectedSizes.width === '23.8%' ? '30%' : '60%' }}
                          >
                            <b>calibration date : </b>  <b>
                              {data.certificate_calibrationDate
                                ? moment(
                                    data.certificate_calibrationDate
                                  ).format("DD-MM-YYYY")
                                : ""}
                            </b> 
                          </div>
                          <div
                            className="bottomDivChildM"
                            style={{ fontSize: selectedSizes.width === '30.7%' ? '50%' : selectedSizes.width === '23.8%' ? '30%' : '60%' , marginTop:"-2px" }}
                          >
                            <b>due date : </b> <b>
                              {data.certificate_nextDueDate
                                ? moment(data.certificate_nextDueDate).format(
                                    "DD-MM-YYYY"
                                  )
                                : ""}
                            </b>
                          </div>
                          <div
                            className="bottomDivChildM"
                            style={{ fontSize: selectedSizes.width === '30.7%' ? '50%' : selectedSizes.width === '23.8%' ? '30%' : '60%' , marginTop:"-2px"}}
                          >
                             <b>calibrated by : </b> <b>
                              {data.datasheet_calibratedby
                                ? data.datasheet_calibratedby
                                : ""}
                            </b>
                          </div>
                        </div>
                        {/* <div
                          style={{
                            width: "25%",
                          }}
                        >
                          <div
                            className="bottomDivChildM"
                            style={{ fontSize: selectedSizes.width === '30.7%' ? '50%' : selectedSizes.width === '23.8%' ? '30%' : '60%' }}
                          >
                            <b>due date</b>
                          </div>
                          <div
                            className="bottomDivChildM"
                            style={{ fontSize: selectedSizes.width === '30.7%' ? '50%' : selectedSizes.width === '23.8%' ? '30%' : '60%' }}
                          >
                            <b>
                              {data.certificate_nextDueDate
                                ? moment(data.certificate_nextDueDate).format(
                                    "DD-MM-YYYY"
                                  )
                                : ""}
                            </b>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "25%",
                          }}
                        >
                          <div
                            className="bottomDivChildM"
                            style={{ fontSize: selectedSizes.width === '30.7%' ? '50%' : selectedSizes.width === '23.8%' ? '30%' : '60%' }}
                          >
                            <b>calibrated by</b>
                          </div>
                          <div
                            className="bottomDivChildM"
                            style={{ fontSize: selectedSizes.width === '30.7%' ? '50%' : selectedSizes.width === '23.8%' ? '30%' : '60%' }}
                          >
                            <b>
                              {data.datasheet_calibratedby
                                ? data.datasheet_calibratedby
                                : ""}
                            </b>
                          </div>
                        </div> */}
                      </td>
                      <td style={{ border: "none" }}>
                        {selectedSizes.width !== "23.8%" ? (
                              <div
                                style={{
                                  textAlign: "right",
                                  paddingRight: "15px",
                                  // width: "25%",
                                   marginTop:"-2px"
                                }}
                              >
                                <QRCode
                                  value={`${DOMAIN}/#/certificate/print/${data.certificate_id}`}
                                  size={
                                    selectedSizes.width === '30.7%'
                                      ? 27
                                      : selectedSizes.width === '40.4%'
                                      ? 27
                                      : 27
                                  }
                                  align="right"
                                />
                              </div>
                            ) : (
                              <div
                                style={{
                                  // width: "25%",
                                  // display:'flex',
                                  textAlign: "right",
                                  paddingRight: "15px",
                                 marginTop:"-2px"
                                }}
                              >
                                <span
                                  style={{
                                    textAlign: "right",
                                    border: "none",
                                    fontSize:
                                      selectedSizes.width === '30.7%'
                                        ? '50%'
                                        : selectedSizes.width === '23.8%'
                                        ? '30%'
                                        : '60%',
                                  }}
                                >
                                {data?.certificateAnalytics_stickerDownloadCounter >
                              0 ? (
                                <div>
                                  Download No.:{" "}
                                  {
                                    data.certificateAnalytics_stickerDownloadCounter
                                  }
                                </div>
                              ) : null}
                                </span>
                              </div>
                            )}

                      </td>
                    </tr>
                    {selectedSizes.width !== "23.8%" && (
                    <tr>
                      <td style={{ border: "none" }}></td>
                      <td
                        style={{
                          textAlign: "right",
                          border: "none",
                        fontSize: selectedSizes.width === '30.7%' ? '50%' : selectedSizes.width === '23.8%' ? '40%' : '60%' }}
                      >
                      {data?.certificateAnalytics_stickerDownloadCounter >
                              0 ? (
                                <div style={{marginTop:"-8px"}}>
                                  Download No.:{" "}
                                  {
                                    data.certificateAnalytics_stickerDownloadCounter
                                  }
                                </div>
                              ) : null}
                      </td>
                    </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  useEffect(() => {
    getExpenseList();
  }, []);

  return (
    <>
     <div style={{display:"flex",justifyContent:"flex-end"}}>            
      <Tooltip title="User Guide" placement="top-start">
        <Button
         onClick={() => {
          multipleQRCertificateGuides.drive();
         }}
        >
          <HelpIcon />
        </Button>
      </Tooltip>
    </div>  
    <TableContainer
      component={Paper}
      sx={{ mt: 2, p: 2 }}
      className="hiddenscroll"
    >
      <Typography variant="h5" component="h5" style={{ float: "center" }}>
        <b>
          <u>Multiple QR Code</u>
        </b>
      </Typography>
      <hr />
      <Box sx={{ width: "90%", m: "1.2rem auto" }}>
        <FormControl
          variant="standard"
          sx={{ minWidth: 230, float: "right", mb: "1rem" }}
        >
          <InputLabel id="demo-simple-select-label">
            Choose Your Sizes
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="certificate-multipleqr_selctsize"
            value={`${selectedSizes.width}-${selectedSizes.height}`}
            onChange={handleSizeChange}
            label="Size"
          >
            {sizeOptions.map((option) => (
              <MenuItem
                key={`${option.width}-${option.height}`}
                value={`${option.width}-${option.height}`}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <div
        id="printStiker"
        className="row border-dark p-2 qrSticker"
        ref={printComponentRef}
        style={{ width: "100%", pageBreakAfter: "auto",margin:"1rem", }}
      >
        <div
          style={{
            display: "grid",
            justifyContent: "center",
            gridGap: "20px",
            gridTemplateColumns: `repeat(2, ${stikerWidth}px)`,
            gridAutoRows: `${stikerHeight}px`,
          }}
        >
          {renderQrModal()}
        </div>
      </div>
      <Button
        id="certificate-multipleqr_download"
        variant="contained"
        size="small"
        className="non"
        sx={{ m: 0, ml: 2, mt: 4 }}
        onClick={() => {
          handlePrint();
          countOfStickerAnalytics(certificateIdsArray);
          // refresh()
        }}
      >
        Print
      </Button>
    </TableContainer>
    </>
  );
}