import moment from "moment";
import { toast } from "react-toastify";
import { BASE_URL, ID_BASE, SRF_ID_BASE } from "../../../global";
import { generateNumber } from "../../../utils/utils";
import generateAudit from "../../audit/audit";
import { srfFields } from "./constants";
import { createSRFAnalytics } from "../../../utils/analytics/srfAnalytics";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";

// helper functions
const parseFormat = (val, format) => {
  switch (format) {
    case "date":
      return moment(val).format("YYYY-MM-DD");
    default:
      return val;
  }
};
const checkChange = (oldState, newState, field, type) => {
  if (oldState[field] !== newState[field]) {
    return `${field} : ${parseFormat(oldState[field], type)} >> ${parseFormat(
      newState[field],
      type
    )}`;
  }
  return "";
};

const checkReadingChange = (newRow, oldRow, insertId) => {
  let change = Object.keys(newRow || {})
    .map((key, i) =>
      oldRow?.[key] !== key
        ? `${key} : ${oldRow?.[key]} >> ${newRow[key]}`
        : false
    )
    .filter((e) => e)
    .join(" , ")
    .replaceAll("#", " ")
    .replaceAll("_rh_", "")
    .replaceAll("_unit_", "");
  return change.length > 0 ? change : insertId ? "Empty" : "";
};

const submitSrfInstruments = (readingRows, srfsId, srfDetails) => {
  readingRows = readingRows.map((row) => ({
    ...row,
    srfsId: srfsId,
    extraColumns: JSON.stringify(row.extraColumns),
    userId: localStorage.getItem("id"),
    calibrationType: srfDetails.calType || 1,
  }));

  let url = BASE_URL;
  return axiosWithToken
    .post(url + "srfInstruments/bulk", readingRows)
    .then((res) => {
      let srfInstrumentIdsList = Array.from(
        { length: res.data.affectedRows },
        (_, index) => res.data.insertId + index
      );
      createSRFAnalytics(srfsId, srfInstrumentIdsList, 25);
    })
    .catch((error) => {
      toast.error("Something Went Wrong!");
      return error;
    });
};

const submitDatasheets = (readingRows, srfDetails, srfId, latestSRFId) => {
  let rowsToUpdate = [];
  let rowsToInsert = [];
  readingRows.forEach((row, index) => {
    row = {
      id: row.id,
      receiptDate: srfDetails.receiptDate
        ? moment(srfDetails.receiptDate).format("YYYY-MM-DD")
        : null,
      clientId: srfDetails.clientId,
      poNumber: srfDetails.poNumber,
      poDate: srfDetails.poDate
        ? moment(srfDetails.poDate).format("YYYY-MM-DD")
        : null,
      address: srfDetails?.address,
      serviceReqNumber: srfDetails.serviceReqNumber,
      JobNumber: `${SRF_ID_BASE + Number(latestSRFId)}/${index + 1}`,
      dcNumber: srfDetails?.dcNumber,
      DCDate: srfDetails.dcDate
        ? moment(srfDetails.dcDate).format("YYYY-MM-DD")
        : null,
      workOrderNumber: ID_BASE + Number(latestSRFId) + "/WO",
      // TODO: are below columns present same data? and why?
      srfInstrumentId: row.srfInstrumentId,
      instrumentId: row.instrumentId,
      serialNumber: row.serialNumber,
      ranges: row.ranges,
      lc: row.lc,
      make: row.make,
      model: row.model,
      department: row.department,
      operatingRange: row.operatingRange,
      DUCID: row.DUCID,
      ConOfDuc: row.ConOfDuc,
      calFrequency: row.calFrequency,
      accuracy: row.accuracy,
      calibrationType: srfDetails?.calType || 1,
      location: row.location,
      locationOfInstrument: row.locationOfInstrument,
      calPoint: row.calPoint,
      calMethod: row.calMethod,
      extraColumns: JSON.stringify(row.extraColumns),
      createdby: localStorage.getItem("id"),
    };
    if (row.id > 0) {
      rowsToUpdate.push(row);
    } else {
      delete row.id;
      rowsToInsert.push(row);
    }
  });
  if (rowsToInsert?.length > 0) {
    axiosWithToken
      .post(BASE_URL + "datasheets/bulk", rowsToInsert)
      .then((res) => {
        console.log("datasheets created successfully!");
        return res;
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
        return error;
      });
  }
  rowsToUpdate.forEach((row) => {
    axiosWithToken
      .patch(BASE_URL + `datasheets/${row.id}`, row)
      .then((res) => {
        console.log(`datasheet (id: ${row.id}) updated successfully!`);
        return res;
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
        return error;
      });
  });
};

const submitCertificate = (
  readingRows,
  srfDetails,
  srfId,
  settingList = [],
  latestSRFId,
) => {
  let rowsToUpdate = [];
  let rowsToInsert = [];
  readingRows.forEach((row, id) => {
    row = {
      id: row.id,
      receiptDate: srfDetails.receiptDate
        ? moment(srfDetails.receiptDate).format("YYYY-MM-DD")
        : null,
      clientId: srfDetails.clientId,
      poNumber: srfDetails.poNumber,
      poDate: srfDetails.poDate
        ? moment(srfDetails.poDate).format("YYYY-MM-DD")
        : null,
      dcNumber: srfDetails.dcNumber,
      address: srfDetails.address,
      serviceReqNumber: srfDetails.serviceReqNumber,
      DCDate: srfDetails.dcDate
        ? moment(srfDetails.dcDate).format("YYYY-MM-DD")
        : null,
      instrumentId: row.instrumentId,
      ranges: row.ranges,
      srfInstrumentId: row.srfInstrumentId,
      lc: row.lc,
      serialNumber: row.serialNumber,
      make: row.make,
      model: row.model,
      operatingRange: row.operatingRange,
      department: row.department,
      DUCID: row.DUCID,
      ConOfDuc: row.ConOfDuc,
      accuracy: row.accuracy,
      calibrationType: srfDetails?.calType || 1,
      location: row.location,
      locationOfInstrument: row.locationOfInstrument,
      calPoint: row.calPoint,
      calMethod: row.calMethod,
      extraColumns: JSON.stringify(row.extraColumns),
      createdby: localStorage.getItem("id"),
      approvedby: null,
      reviewedBy: null,
      signedBy: null,
      rejectedBy: null,
      witnessBy: null,
      approvedDate: null,
      reviewedDate: null,
      witnessDate: null,
      signedDate: null,
    };

    if (row.id > 0) {
      rowsToUpdate.push(row);
    } else {
      let certificateNumberByCalType;
      if (srfDetails?.calType === 1) {
        certificateNumberByCalType =
          settingList["Certificate Number NABL"]?.value;
      } else if (srfDetails?.calType === 2) {
        certificateNumberByCalType =
          settingList["Certificate Number IAS"]?.value;
      } else if (srfDetails?.calType === 3) {
        certificateNumberByCalType =
          settingList["Certificate Number NONNABL"]?.value;
      }
      row["certificateNumber"] =
        generateNumber(
          certificateNumberByCalType,
          `${SRF_ID_BASE + Number(latestSRFId)}/`,
          {
            branch: srfDetails?.branch,
            discipline: srfDetails?.discipline,
            calibrationProcedureNo: srfDetails?.calibrationProcedureNo,
          }
        ) + `${SRF_ID_BASE + Number(latestSRFId)}/${id + 1}`;
     
      row["ULRNo"] = generateNumber(
        settingList["ULR number"]?.value,
        SRF_ID_BASE + Number(settingList["ULR ID"]?.value) + id
      );
      delete row.id;
      rowsToInsert.push(row);
    }
  });

  if (rowsToInsert?.length > 0) {
    axiosWithToken
      .post(BASE_URL + "certificates/bulk", rowsToInsert)
      .then((res) => {
        axiosWithToken.patch(
          BASE_URL + `settings/${settingList["ULR ID"]?.id}`,
          {
            value: Number(settingList["ULR ID"]?.value) + 1,
          }
        );
        console.log("Certificates created successfully!");
        return res;
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
        return error;
      });
  }

  rowsToUpdate.forEach((row) => {
    axiosWithToken
      .patch(BASE_URL + `certificates/${row.id}`, row)
      .then((res) => {
        console.log(`Certificate (id: ${row.id}) updated successfully!`);
        return res;
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
        return error;
      });
  });
};

const submitChangeLog = (srfDetails, readingRows) => {
  // 1. submit SRF change log
  let changeLog = [];
  let oldSrfDetails = JSON.parse(sessionStorage.getItem("oldSrfDetails")) || {};
  srfFields.map((s) =>
    changeLog.push(checkChange(oldSrfDetails, srfDetails, s[0], s[1]))
  );
  changeLog = changeLog.filter((e) => e !== "").join(" , ");
  console.log("SRF changes : ", changeLog);

  if (changeLog !== "")
    generateAudit(
      "srfs",
      srfDetails?.serviceReqNumber,
      localStorage.getItem("id"),
      moment().format("yyyy-MM-DD HH:mm:ss"),
      Object.keys(oldSrfDetails) > 0 ? "Updated" : "Created",
      changeLog,
      srfDetails.clientId
    );

  // 2. submit SRFInstrument change log
  let changeLog2 = [];
  let oldReadingRows =
    JSON.parse(sessionStorage.getItem("oldSrfInstruments")) || [];
  readingRows.forEach((newRow, index) => {
    changeLog2.push([
      newRow["id"],
      checkReadingChange(newRow, oldReadingRows[index], newRow.id),
    ]);
  });
  changeLog2 = changeLog2.filter((e) => e[1] !== "");
  console.log("change log 2 : ", changeLog2);

  changeLog2.map(
    async (r, i) =>
      await generateAudit(
        "srfInstruments",
        r[0],
        localStorage.getItem("id"),
        moment().format("yyyy-MM-DD HH:mm:ss"),
        oldReadingRows?.length > 0 && i < oldReadingRows?.length
          ? "Updated"
          : "Created",
        r[1],
        srfDetails.clientId
      )
  );
};

const handleCERFForm = (CSRFFormPayload, insertId, isUpdate, csrfID) => {
  let payload = { ...CSRFFormPayload, srfId: insertId };

  if (csrfID) {
    axiosWithToken
      .patch(BASE_URL + `csrfForm/${csrfID}`, payload)
      .then((res) => {
        if (res.data.insertId == 0) {
          axiosWithToken
            .post(BASE_URL + "csrfForm", payload)
            .then((res) => {
              // toast.success("CERF Form Save Successfully!");
            })
            .catch((error) => {
              console.log("Something Went Wrong!");
            });
        }
      })
      .catch((error) => {
        console.log("Something Went Wrong!");
      });
  } else {
    axiosWithToken
      .post(BASE_URL + "csrfForm", payload)
      .then((res) => {
        // toast.success("CERF Form Save Successfully!");
      })
      .catch((error) => {
        console.log("Something Went Wrong!");
      });
  }
};

export const handleCreateSubmit = (
  srfDetails,
  readingRows,
  settings,
  setLoader,
  CSRFFormPayload,
  openErrorModal,
  latestSRFId
) => {
  setLoader(true);
  let srfRow = {
    clientId: srfDetails.clientId,
    receiptDate: srfDetails.receiptDate
      ? moment(srfDetails.receiptDate).format("YYYY-MM-DD")
      : null,
    dcNumber: srfDetails.dcNumber,
    dcDate: srfDetails.dcDate
      ? moment(srfDetails.dcDate).format("YYYY-MM-DD")
      : null,
    entryDate: srfDetails.entryDate
      ? moment(srfDetails.entryDate).format("YYYY-MM-DD")
      : null,
    committedDeliveryDate: srfDetails.committedDeliveryDate
      ? moment(srfDetails.committedDeliveryDate).format("YYYY-MM-DD")
      : null,
    serviceReqNumber: srfDetails.serviceReqNumber,
    companyHierarchy: srfDetails.companyHierarchy,
    description: srfDetails.description,
    address: srfDetails.address,
    poNumber: srfDetails.poNumber,
    contactPersonDetails: JSON.stringify(srfDetails.contactPersonDetails),
    poDate: srfDetails.poDate
      ? moment(srfDetails.poDate).format("YYYY-MM-DD")
      : null,
    billingId: srfDetails.billingId,
    createdby: localStorage.getItem("id"),
    customerAnnextureFilePaths:
      srfDetails.customerAnnextureFilePaths.join(",") || null,
    calType: srfDetails.calType || null,
  };

  axiosWithToken
    .post(BASE_URL + "srfs", srfRow)
    .then((res) => {
      Promise.all([
        submitSrfInstruments(readingRows, res.data.insertId, srfDetails),
        submitDatasheets(readingRows, srfDetails, res.data.insertId, latestSRFId),
        submitCertificate(readingRows, srfDetails, res.data.insertId, settings, latestSRFId),

        // TODO: audit functionality is disabled
        // TODO: change the logic of below function to support create change log
        // submitChangeLog(srfDetails, readingRows),
        handleCERFForm(CSRFFormPayload, res.data.insertId, false),
      ])
        .then((res) => {
          toast.success("SRF created successfully!");
          setLoader(false);
          setTimeout(() => {
            window.location.reload(false);
          }, 500);
        })
        .catch((err) => {
          console.log(
            "error while creating the srf instruments, error : ",
            err
          );
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(
                  err.message,
                  "handleCreateSubmit",
                  "SrfSubmitHanlders"
                )
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
          setLoader(false);
        });
    })
    .catch((err) => {
      console.log("error while creating the srf, error : ", err);
      toast.error(
        <h6
          onClick={() =>
            openErrorModal(
              err.message,
              "handleCreateSubmit",
              "SrfSubmitHanlders"
            )
          }
        >
          <u>Error: {err.message}</u>
        </h6>
      );
      setLoader(false);
    });
};

export const handleUpdateSubmit = async (
  srfDetails,
  readingRows,
  srfInstrumentsToDelete,
  settings,
  setLoader,
  CSRFFormPayload,
  csrfID,
  openErrorModal
) => {
  let srfRow = {
    receiptDate: srfDetails.receiptDate
      ? moment(srfDetails.receiptDate).format("YYYY-MM-DD")
      : null,
    dcNumber: srfDetails.dcNumber,
    dcDate: srfDetails.dcDate
      ? moment(srfDetails.dcDate).format("YYYY-MM-DD")
      : null,
    entryDate: srfDetails.entryDate
      ? moment(srfDetails.entryDate).format("YYYY-MM-DD")
      : null,
    committedDeliveryDate: srfDetails.committedDeliveryDate
      ? moment(srfDetails.committedDeliveryDate).format("YYYY-MM-DD")
      : null,
    serviceReqNumber: srfDetails.serviceReqNumber,
    companyHierarchy: srfDetails.companyHierarchy,
    jobNumber: srfDetails.jobNumber,
    description: srfDetails.description,
    address: srfDetails.address,
    poNumber: srfDetails.poNumber,
    contactPersonDetails: JSON.stringify(srfDetails.contactPersonDetails),
    poDate: srfDetails.poDate
      ? moment(srfDetails.poDate).format("YYYY-MM-DD")
      : null,
    billingId: srfDetails.billingId,
    customerAnnextureFilePaths:
      srfDetails.customerAnnextureFilePaths.join(",") || null,
    calType: srfDetails.calType || null,
  };

  Promise.all([
    (async () => {
      await axiosWithToken.patch(BASE_URL + `srfs/${srfDetails?.id}`, srfRow);

      // delete the removed rows by user
      if (srfInstrumentsToDelete?.length > 0) {
        let tableList = ["srfInstruments", "datasheets", "certificates"];
        await Promise.all([
          tableList.map(async (tableName) => {
            await axiosWithToken.delete(
              BASE_URL +
                `${tableName}/bulk?_ids=${srfInstrumentsToDelete?.join(",")}`
            );
          }),
        ]);
      }

      // update/insert SRFInstrument rows
      await Promise.all([
        readingRows.map(async (row) => {
          let _row = { ...row };
          row["srfsId"] = _row["srfsId"] = srfDetails?.id;
          _row["extraColumns"] = JSON.stringify(row["extraColumns"]);
          _row["calibrationType"] = srfDetails.calType || 1;

          if (row.id > 0 && !srfInstrumentsToDelete.includes(row.id)) {
            await axiosWithToken.patch(
              BASE_URL + `srfInstruments/${_row.id}`,
              _row
            );
          } else {
            await axiosWithToken
              .post(BASE_URL + `srfInstruments`, _row)
              .then((res) => {
                createSRFAnalytics(srfDetails?.id, [res?.data?.insertId], 25);
              });
          }
        }),
      ])
        .then(async (res) => {
          let latestSRFId = srfDetails.serviceReqNumber?.split('/')?.pop();
          submitDatasheets(readingRows, srfDetails, srfDetails?.id, latestSRFId-1);
          submitCertificate(readingRows, srfDetails, srfDetails?.id, settings,latestSRFId-1);
          submitChangeLog(srfDetails, readingRows);
          handleCERFForm(CSRFFormPayload, srfDetails?.id, true, csrfID);
        })
        .catch((err) => {
          console.log("error : ", err);
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(
                  err.message,
                  "handleUpdateSubmit",
                  "SrfSubmitHanlders"
                )
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
          setLoader(false);
        });
    })(),
  ])
    .then(async (res) => {
      toast.success("SRF updated successfully!");
      setTimeout(() => {
        window.location.reload(false);
      }, 500);
    })
    .catch((err) => {
      console.log("error while updating the srf, error : ", err);
      toast.error(
        <h6
          onClick={() =>
            openErrorModal(
              err.message,
              "handleUpdateSubmit",
              "SrfSubmitHanlders"
            )
          }
        >
          <u>Error: {err.message}</u>
        </h6>
      );
      setLoader(false);
    });
};
